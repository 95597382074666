import { ComponentElem } from "@components/interfaces";
import PopupBase from "./PopupBase";
import Popups from "./Popups";
import { gsap } from "gsap";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import Geom from "@ts/sframe/Geom";


export default class VideoPlayer extends PopupBase
{
  static instance: VideoPlayer;

  autoClose_cover: boolean = true;

  coverColor: string = `rgba(6, 7, 7, 0.7)`;

  // dataList: any[];

  // currentIndex: number;
  // isImageOnLoading: boolean = false;

  $content: JQuery;
  $container: JQuery;

  private _player: any;

  private _isPlayerReady: boolean = false;
  private _queueVideoId: string = null;

  constructor(elem: ComponentElem)
  {
    super(elem);

    VideoPlayer.instance = this;

    this.$content = this.$root.find(".content");
    this.$container = this.$root.find(".video-container");

    this.loadApi();
  }

  loadApi()
  {
    let self = this;

    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    var player, playing = false;
    ///@ts-ignore
    window.onYouTubeIframeAPIReady = () =>
    {
      this._isPlayerReady = true;

      if (this._queueVideoId) 
      {
        this.playVideo(this._queueVideoId);
        this._queueVideoId = null;
      }
    }
  }

  playVideo(videoId: string, isShort: boolean = false)
  {
    if (!this._isPlayerReady)
    {
      console.log("youtube api not loaded yet");
      this._queueVideoId = videoId;
      return;
    }

    let self = this;

    Popups.to("video-player");

    this.$root.attr("is-short", isShort? "true": "false");
    
    this.buildPlayer(videoId);
    // this._fitVideoSize(videoId);
    
  }

  // private _fitVideoSize(videoId: string)
  // {
  //   let self = this;
    
  //   var url = 'https://www.youtube.com/watch?v=' + videoId;
  //   $.getJSON('https://noembed.com/embed', { format: 'json', url: url }, function (data)
  //   {
  //     console.log('video size: ' + data.width + 'x' + data.height);
  //     // console.log(data);

  //     const MAX_WIDTH = 620;
  //     let newHeight = MAX_WIDTH / data.width * data.height;
  //     // console.log(newHeight);
      
  //     self.$root.find(">.content").height(newHeight);
      
  //     self.buildPlayer(videoId);
  //   });
    
  // }

  private buildPlayer(videoId: string)
  {
    let self = this;

    let $container = this.$root.find(".video-container"),
      $video = $(`<div class="video"></div>`)

    $container.empty().append($video);

    let player = this._player = new window.YT.Player($video[0], {
      width: "100%",
      height: "100%",
      videoId: videoId,
      playerVars: {
        'playsinline': 1,
        'rel': 0,
      },
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });

    ///@ts-ignore
    function onPlayerReady(event)
    {
      // console.log("player ready");
      event.target.playVideo(0);
    }

    ///@ts-ignore
    function onPlayerStateChange(event: any)
    {
      // console.log(event);
      if(event.data == 0) Popups.close();
      
      // console.log("player state change");

      // if (event.data == window.YT.PlayerState.PLAYING) {
      //   playing = true;

      //   if(!self._isEventSent)
      //   {
      //     self._isEventSent = true;
      //     if(window.gtag) window.gtag("event", "Cachew_Click_OTV", {"event_label": "品牌頁_OTV_PlayBTN"});
      //   }
      // }
    }

  }
}