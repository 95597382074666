import { ComponentElem } from "@components/interfaces";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import gsap from "gsap";
import Pages from "../common/PageRoot";
import { PopupDefineDic, PopupDefine } from "@components/interfaces";
import PhotoViewer from "./PhotoViewer";
import NavMenu from "./NavMenu";
import VideoPlayer from "./VideoPlayer";
import EZAlert from "./EZAlert";

let $root: JQuery,
  $cover: JQuery,
  $dropdownMenus: JQuery,
  $dropdownMenusCover: JQuery,
  _isLocking: boolean = false,
  _currentPopup: string = "",
  _currentMenu: string = "",
  _setBackPopup: string = undefined;


let _popupDic: PopupDefineDic =
{
};

type MenuOptions = {
  position?: "absolute" | "sticky" | "popup",
  align?: "left" | "center" | "right"
  offsetTop?: number,
  offsetLeft?: number
};

let _popupTl: any;

class Popups
{
  static getCurrentPopup()
  {
    return _currentPopup;
  }

  static init()
  {
    window._ez_popup_to_ = (id: string) =>
    {
      Popups.to(id);
    };

    this.registComponents({
      // "photo-viewer": PhotoViewer,
      "nav-menu": NavMenu,
      "video-player": VideoPlayer,
      "ez-alert": EZAlert,
    });

    $root = $("#popups");
    $cover = $root.find(".cover").on("click", (event: Event) =>
    {
      event.preventDefault();
      if (_currentPopup && _popupDic[_currentPopup].instance.autoClose_cover) {
        this.close();
      }
    });

    updateActive();

    for (const key in _popupDic) {
      if (Object.prototype.hasOwnProperty.call(_popupDic, key)) {
        let obj: PopupDefine = _popupDic[key],
          elem = document.querySelector(`[popup-id="${key}"]`) as ComponentElem;
        if (!elem) throw new Error(`popup element for [${key}] is not exist\n(可能是未在頁面中加入對應的模板)`);

        // console.log(key);
        // console.log(obj.componentClass);

        let componentClass: any = obj.componentClass ? obj.componentClass : obj;
        obj.instance = new componentClass(elem);
        obj.instance.popupId = key;

      }
    }

    $dropdownMenus = $("#dropdown-menus");
    $dropdownMenusCover = $dropdownMenus.find(".cover").on("click", (event: Event) =>
    {
      event.preventDefault();
      this.closeMenu();
    });

    ViewportRoot.emitter.addListener("updated", () =>
    {
      if (ViewportRoot.isMajorChange) onMajorViewportChange();
      $root.css("min-height", ViewportRoot.height);
    });

    $root.attr("init", "true");

    $("#shared-popups").detach();
  }

  static getInstance(popupName: string)
  {
    if (!_popupDic[popupName]) return null;
    return _popupDic[popupName].instance;
  }

  static to(targetPopup: string = "", setBackPopup?: string)
  {
    // if (_isLocking) return;
    if (_isLocking && _popupTl) _popupTl.progress(1);

    if (targetPopup === _currentPopup) return
    if (!_popupDic[targetPopup] && targetPopup !== "") throw new Error(`popup [${targetPopup}] 不存在`);

    return new Promise((resolve) =>
    {
      _isLocking = true;

      _setBackPopup = setBackPopup;

      const duration = .15,
        ease = "power2.out",
        offset = 50;

      let oldPopup = _currentPopup
      _currentPopup = targetPopup;

      let tl = _popupTl = gsap.timeline();

      if (oldPopup === "") updateActive();

      if (oldPopup === "") {
        tl.to($cover, { duration: .2, opacity: 1 }, 0);
      }

      if (oldPopup !== "") {
        let obj: PopupDefine = _popupDic[oldPopup];

        if (obj.instance.popupAlign === 4) tl.to(obj.instance.$root, { duration: duration, x: -offset, opacity: 0, ease: ease }, 0);
        else if (obj.instance.popupAlign === 8) tl.to(obj.instance.$root, { duration: duration, y: -offset, opacity: 0, ease: ease }, 0);
        else if (obj.instance.popupAlign === 2) tl.to(obj.instance.$root, { duration: duration, y: offset, opacity: 0, ease: ease }, 0);
        else tl.to(obj.instance.$root, { duration: duration, y: -offset, opacity: 0, ease: ease }, 0);

        tl.add(() =>
        {
          obj.instance.isOnStage = false;
          obj.instance.$root.attr("style", "").detach();
        });
      }

      if (_currentPopup !== "") {
        let obj: PopupDefine = _popupDic[_currentPopup];

        tl.add(() =>
        {
          $cover.css("background-color", obj.instance.coverColor);

          $root.attr("content-align", obj.instance.popupAlign);
          obj.instance.isOnStage = true;
          $root.append(obj.instance.$root);
        });


        tl.addLabel("contentIn");

        if (obj.instance.popupAlign === 4) {
          tl.set(obj.instance.$root, { duration: duration, x: -offset, opacity: 0 }, "contentIn");
          tl.to(obj.instance.$root, { duration: duration, x: 0, opacity: 1, ease: ease }, "contentIn");
        }
        else if (obj.instance.popupAlign === 8) {
          tl.set(obj.instance.$root, { duration: duration, y: -offset, opacity: 0 }, "contentIn");
          tl.to(obj.instance.$root, { duration: duration, y: 0, opacity: 1, ease: ease }, "contentIn");
        }
        else {
          tl.set(obj.instance.$root, { duration: duration, y: offset, opacity: 0 }, "contentIn");
          tl.to(obj.instance.$root, { duration: duration, y: 0, opacity: 1, ease: ease }, "contentIn");
        }
      }

      if (_currentPopup === "") {
        tl.to($cover, { duration: .2, opacity: 0 }, "contentIn");
      }

      tl.add(() =>
      {
        updateActive();
        _isLocking = false;

        // if(cb) cb();
        resolve(true);
      });
    });



  }

  static openMenu(targetPopup: string = "", targetElem?: HTMLElement, options?: MenuOptions)
  {
    if (targetPopup === _currentMenu) return;

    let defaultOptions: MenuOptions = {
      position: "absolute",
      align: "center",
      offsetTop: 10,
      offsetLeft: 0
    };

    options = {
      ...defaultOptions,
      ...options
    };

    // console.log(`target: ${targetPopup}`);
    // console.log(targetElem);

    if (targetPopup === "" && _currentMenu) {

      removeMenu();
      _currentMenu = "";
    }
    else {
      _currentMenu = targetPopup;


      if (options.position === "sticky") {

        let rect = targetElem.getBoundingClientRect(),
          top = rect.top + rect.height + options.offsetTop,
          left = rect.left + window.scrollX + options.offsetLeft;

        completeMenu(rect, left, top, options);
      }
      else if (options.position === "absolute") {

        let rect = targetElem.getBoundingClientRect(),
          top = rect.top + window.scrollY + rect.height + options.offsetTop,
          dx = Math.max(0, (ViewportRoot.width - ViewportRoot.getScrollbarWidth() - 1280) * .5),
          left = rect.left + window.scrollX - dx + options.offsetLeft;

        // console.log(ViewportRoot.scrollbarWidth);


        completeMenu(rect, left, top, options);
      }
    }
  }

  static openMenuAsPopup(targetPopup: string = "")
  {
    if (targetPopup === _currentMenu) return;
    _currentMenu = targetPopup;

    $dropdownMenus.attr("active", "true");

    let obj = _popupDic[_currentMenu],
      $popup = obj.instance.$root;

    // $popup.attr("mode", "menu");


    let $cover = $dropdownMenus.find(".cover");

    $dropdownMenus.attr("position", "popup").append($popup);

    let tl = gsap.timeline();
    tl.set($dropdownMenus, { opacity: 1 });
    tl.set($cover, { opacity: 0 });
    tl.set($popup, { y: 40 });
    tl.to($cover, { duration: .15, opacity: 1 });
    tl.to($popup, { duration: .2, y: 0 }, 0);

    // gsap.from($cover, { duration: .15, opacity: 0 });
    // gsap.from($popup, { duration: .15, y: 20, delay: .1 });
  }

  static close()
  {
    if (_setBackPopup) {
      let string = _setBackPopup;
      _setBackPopup = undefined;
      return this.to(string);
    }
    else {
      return this.to("");
    }
  }

  static closeMenu()
  {
    this.openMenu("");
  }

  static registComponents(dic: PopupDefineDic)
  {
    _popupDic = Object.assign({}, dic, _popupDic);
  }
}

function onMajorViewportChange()
{
  if (_currentMenu) {
    let obj = _popupDic[_currentMenu];
    if (obj.instance.autoClose_menu) Popups.closeMenu();
  }

  if (_currentPopup) {
    let obj = _popupDic[_currentPopup];
    if (obj.instance.autoClose_popup) Popups.close();
  }
}

function completeMenu(rect: DOMRect, left: number, top: number, options: MenuOptions): void
{
  $dropdownMenus.attr("active", "true");

  let obj = _popupDic[_currentMenu],
    $popup = obj.instance.$root;
  if (obj.instance.setMinWidth) obj.instance.setMinWidth(rect.width);

  $popup.attr("mode", "menu");

  $dropdownMenus.attr("position", options.position).append($popup)

  let width = $popup.width();


  switch (options.align) {

    case "center":
      left = left + rect.width * .5 - width * .5;
      break;

    case "right":
      left = left + rect.width - width;
      break;
  }

  // gsap.from($popup, {duration: .15, transformOrigin: "center top", scaleX: ".8", ease: "power1.out"});
  gsap.set($dropdownMenus, { opacity: 1 });
  gsap.from($popup, { duration: .15, opacity: 0, ease: "power1.out" });

  if (options.position === "sticky") {
    let vpWidth = Math.max(ViewportRoot.width, 1440);

    $popup.css({
      "left": "50%",
      "margin-left": left - vpWidth * .5,
      "top": top + "px"
    });
  }
  else {
    $popup.css({
      "left": left + "px",
      "top": top + "px"
    });
  }
}

function removeMenu()
{

  let obj = _popupDic[_currentMenu];

  // let tl = gsap.timeline();

  // tl.to($dropdownMenus, { duration: .15, opacity: 0 });

  // tl.add(() =>
  // {

  $dropdownMenus.attr("active", "false").attr("position", "");


  if (obj) {
    if (obj.instance.clearDropdownSetting) obj.instance.clearDropdownSetting();

    obj.instance.$root.attr("style", "").detach().attr("mode", "popup");
  }

  // });
}

function updateActive()
{
  let isActive = _currentPopup !== "";
  $root.attr("active", isActive ? "true" : "false");
  Pages.toggleScrollLock(isActive);
}

export default Popups;