import GS from "../GlobalSetting";
import { Viewport } from "./Viewport";

export enum ViewportIndex{
  mobile = 0,
  pc = 1
}

export function getViewportName()
{  
  return ViewportRoot.index === 0? "mobile": "pc";
}

let ViewportRoot = new Viewport<ViewportIndex>([GS.MOBILE_WIDTH, GS.MAX_WIDTH]);
export default ViewportRoot;