/* change log
    1.0.1
        支援 addListener 和 removeListener
        移除 bind, 統一由 listener 回應捲動事件
    1.0.2
        新增 update 方法, 強制更新狀態
        改變 testDom 方法的判定
        新增 testDomFromMiddle 方法, 類似 testDom, 但是判定內容以判定目標的中間為準
    1.0.3
        新增 getPosition 方法, 取用目前 window scrollTop 和 scrollLeft
    1.0.4
        testDomFromMiddle 新增 containerSizeScale 參數, 決定判斷容器的大小
    1.0.5
        scroll height 取得方式變更
    1.0.7
        更換成 typescript, 移置 sframe namespace
 */

import gsap from "gsap";
import Utility from "./Utility";


var _isLocking = false,
  _listenerDic: { [key: string]: Function } = {},
  _scrollBound =
  {
    top: 0,
    left: 0,
    width: 0,
    height: 0
  },
  _tweenDic = { scrollTop: 0, scrollLeft: 0 };

var self =
{
  _isActive: false,

  init: function ()
  {
    window.scrollTo(0, 0);

    return self;
  },

  addListener: function (id: string, func: Function)
  {
    _listenerDic[id] = func;

    return self;
  },

  removeListener: function (id: string)
  {
    delete _listenerDic[id];

    return self;
  },

  active: function ()
  {
    self._isActive = true;

    window.addEventListener("scroll", onScroll);

    updateScrollTop();

    return self;
  },

  disactive: function ()
  {
    self._isActive = false;
    window.removeEventListener("scroll", onScroll);

    return self;
  },

  // topOffset and bottomOffset 代表檢測內容的上下偏移 (1.0.1版為容器的上下偏移)
  testDom: function (dom: HTMLElement, topOffset: number, bottomOffset: number)
  {
    var bound = dom.getBoundingClientRect();

    if (topOffset === undefined) topOffset = 0;
    if (bottomOffset === undefined) bottomOffset = 0;

    var top = 0,
      bottom = _scrollBound.height;

    var boundTop = bound.top + topOffset,
      boundBottom = bound.bottom + bottomOffset;

    var topInside = (boundTop >= top && boundTop <= bottom),
      bottomInside = (boundBottom >= top && boundBottom <= bottom);

    return {
      bound: bound,
      topInside: topInside,
      bottomInside: bottomInside
    };
  },

  // 測試目標 boundingClientRect 中線是否進入 viewport 中
  testDomFromMiddle: function (dom: HTMLElement, topOffset: number = 0, bottomOffset: number = 0)
  {
    var bound = dom.getBoundingClientRect();

    if (topOffset === undefined) topOffset = 0;
    if (bottomOffset === undefined) bottomOffset = 0;

    var top = 0,
      bottom = _scrollBound.height;

    var boundMiddle = bound.top + bound.height * .5,
      boundTop = boundMiddle + topOffset,
      boundBottom = boundMiddle + bottomOffset;

    // console.log(bound);
    // console.log(top);
    // console.log(bottom);
    // console.log(boundMiddle);

    var topInside = (boundTop >= top && boundTop <= bottom),
      bottomInside = (boundBottom >= top && boundBottom <= bottom);

    return {
      bound: bound,
      topInside: topInside,
      bottomInside: bottomInside,
      bothInside: topInside && bottomInside,
      test: _scrollBound.height
    };
  },

  // 測試目標 boundingClientRect 是否包含 viewport 中線
  testDomContainScreenMiddle: function (dom: HTMLElement)
  {
    var bound = dom.getBoundingClientRect(),
      screenMiddleY = _scrollBound.height * .5;

    return (bound.top <= screenMiddleY) && (bound.top + bound.height >= screenMiddleY);
  },

  scrollTo: function (targetTop: number, cb: Function, __speed: number)
  {

    var speed = __speed ? __speed : 1000,
      //dy = Math.abs(targetTop - _tweenDic.scrollTop),
      dy = Math.abs(targetTop - $(window).scrollTop()),
      duration = Math.min(.8, dy / speed);

    gsap.killTweensOf(_tweenDic);
    _tweenDic.scrollTop = $(window).scrollTop();

    gsap.to(_tweenDic, {
      duration: duration, scrollTop: targetTop, onStart: function ()
      {
      }, onUpdate: function ()
      {
        //console.log(_tweenDic.scrollTop + " : " + $(window).scrollTop());
        window.scrollTo($(window).scrollLeft(), _tweenDic.scrollTop);
        //$(window).scrollTop(_tweenDic.scrollTop);

      }, onComplete: cb as gsap.Callback
    });
  },

  update: function (specId?: string)
  {
    updateScrollTop(true, specId);
  },

  getPosition: function ()
  {
    return _tweenDic;
  }
};

function onScroll(evt: Event){
  updateScrollTop();
}

function updateScrollTop(forceExecute?: boolean, specId?: string)
{
  if (!forceExecute && _isLocking) return;
  if (!forceExecute && !self._isActive) return;

  var doc = document.documentElement;
  _scrollBound.left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  _scrollBound.top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  _scrollBound.width = $(window).width();

  // _scrollBound.height = $(window).height();
  _scrollBound.height = window.innerHeight;

  if (Utility.getMobileOperatingSystem() == "iOS") {
    _scrollBound.height = document.documentElement.clientHeight;
  }

  // console.log(_scrollBound.top);
  // console.log("t: " + (window.pageYOffset || document.documentElement.scrollTop));

  _tweenDic.scrollTop = $(window).scrollTop();
  _tweenDic.scrollLeft = $(window).scrollLeft();

  //if(_cbOnScrolling) _cbOnScrolling.call(null, _scrollBound);
  var id, func;
  for (id in _listenerDic) {
    func = _listenerDic[id];
    if (specId) {
      if (specId === id) {
        func.call(null, _scrollBound);
      }
    }
    else {
      func.call(null, _scrollBound);
    }
  }
};

export var ScrollListener = self;


