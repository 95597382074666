import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";

export default class RwdPicture extends ComponentBase
{
  manualLoaded: boolean = true;

  constructor(elem: ComponentElem)
  {
    super(elem);

    this.manualLoaded = (this.$root.attr("lazy-loading") === "manual")? false: true;
    
  }

  load(onload?: Function)
  {
    let img = this.$root.find(`img`)[0],
    $souces = this.$root.find("source");

    $.each($souces, (index, sourceElem)=>
    {
      sourceElem.setAttribute("srcset", sourceElem.dataset["srcset"]);
    });

    img.src = img.dataset.src;

    img.onload = ()=>
    {
      // console.log("image loaded");
      // console.log(img.currentSrc);

      this.$root.attr("lazy-loading", "done");

      this.manualLoaded = true;
      
      if(onload) onload.call(null);

      img.onload = null;
    }
  }
}