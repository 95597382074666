import { ScrollListener } from "../../ts/sframe/ScrollListener";
import PhotoViewer from "../popups/PhotoViewer";
import Popups from "../popups/Popups";
import gsap from "gsap";


let $root: JQuery,
  $userIcon: JQuery

let _isInit = false;

export default class Nav
{
  static init()
  {
    $root = $('nav');
    _isInit = true;

    // this._setupTrigger();

    $root.find(".menu-trigger").on("click", (event: JQuery.ClickEvent) =>
    {
      event.preventDefault();

      // Popups.to("photo-viewer");
      // PhotoViewer.instance.showSinglePhoto("./static/images/photo-examples/1.png");
      Popups.to("nav-menu");
    });
  }

  private static _setupTrigger()
  {

    let isBlockMode = false;

    let $base = $root.find(".trigger-base"),
      $img = $root.find(".trigger-image");

    ScrollListener.addListener("nav-trigger", (bound: any) =>
    {
      let oldCheck = isBlockMode;
      isBlockMode = bound.top > 200;

      if (oldCheck !== isBlockMode)
      {
        if (isBlockMode)
        {
          gsap.to($base, {duration: .4, opacity: 1, scale: 1, ease: "back.out"});
          gsap.set($img, {scale: .6});
          gsap.to($img, {duration: .4, scale: 1, ease: "back.out(4)"});
        }
        else
        {
          gsap.to($base, {duration: .4, opacity: 0, scale: 0, ease: "power1.out"});
          gsap.set($img, {scale: .6});
          gsap.to($img, {duration: .4, scale: 1, ease: "back.out(4)"});
        }
      }



    });
  }

  static isInit() { return _isInit; }

  static resize(scale: number = 1)
  {
    if (scale === 1)
    {
      $root.css({
        "transform": "",
        "transformOrigin": ""
      })
    }
    else
    {
      $root.css({
        "transform": `scale(${scale})`,
        "transformOrigin": "0 0"
      })

    }
  }
}