import { ComponentElem } from "@components/interfaces";
import PopupBase from "@components/popups/PopupBase";
import { Common } from "../common/CommonTypes";
import Popups from "./Popups";
import Tools from "../../ts/Tools";

export default class NavMenu extends PopupBase
{
  popupAlign: Common.Align = 8;
  
  constructor(elem: ComponentElem)
  {
    super(elem);

    let self = this;

    let $btn = this.$root.find(".btn").on("click", function(event:JQuery.ClickEvent)
    {
      event.preventDefault();

      let href = this.getAttribute("href");
      // console.log(href);
      
      Popups.close().then(()=>
      {
        // window.Hash.to(href);
        let id = href.replace(`#`, "");
        Tools.scrollToElement(id);
      });

      return;
    });
  }
}