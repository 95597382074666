import RwdPicture from "./RwdPicture";
import { ComponentDefineDic, ComponentElem } from "./interfaces";

let _componentDic: ComponentDefineDic =
{
  "rwd-picture": RwdPicture
}

export default class Components
{
  static init()
  { 
    Components.bindScripts(null, true);
    Components.seekAndBind("collapse-wrapper");
  }

  static bindScripts(targetElem?: HTMLElement, catchSamples: boolean = false)
  {
    let $doms: JQuery = targetElem ? $(targetElem).find('[component]') : $('[component]');

    // 先抓取樣本
    if (catchSamples) {
      $.each($doms, (index: Number, elem: HTMLElement) =>
      {
        let componentName: string = elem.getAttribute('component');
        // console.log(componentName);
        if (_componentDic[componentName]) {
          let obj = _componentDic[componentName];
          let componentClass: any = obj.componentClass? obj.componentClass: obj;

          if (elem.getAttribute("is-sample") === "true") {
            // console.log(componentName);

            elem.removeAttribute("is-sample");
            $(elem).detach();

            if (!componentClass.$sample) {
              componentClass.$sample = $(elem).clone();
              let $doms = componentClass.$sample.find('[is-sample="true"]');
              $.each($doms, (index, childElem) =>
              {
                childElem.removeAttribute("is-sample");
                // $(childElem).detach();
              });
            }
          }
        }
      });
    }

    // 再綁定程式
    $.each($doms, (index: Number, elem: HTMLElement) =>
    {
      // true: 手動
      let manualBind: string = elem.getAttribute("manual-bind");


      ///@ts-ignore
      if (elem._component) {
        // 忽略重複綁定 (因為引進了依賴綁定 relyList 的邏輯)
        // let componentName: string = elem.getAttribute('component');
        // console.log(`script for component[${componentName}] is alreay bind`);
      }
      else if (manualBind !== "true") {
        let componentName: string = elem.getAttribute('component');

        if (_componentDic[componentName]) {
          // console.log(componentName);

          let obj = _componentDic[componentName];

          this.bindScripts(elem); // 先掃描內部元素
          
          let componentClass: any = obj.componentClass? obj.componentClass: obj;
          new componentClass(elem);
        }
      }
    });
  }

  static seekAndBind(componentName: string)
  {
    let elemList = $(`[component="${componentName}"]`).toArray(),
      obj = _componentDic[componentName];

    if (obj) {
      elemList.forEach((elem: ComponentElem, index)=>
      {
        let componentClass: any = obj.componentClass? obj.componentClass: obj;
        new componentClass(elem);
        // new obj.componentClass(elem); 
      });
    }
  }


  static registComponents(dic: ComponentDefineDic)
  {
    _componentDic = Object.assign({}, dic, _componentDic);
    // console.log(_componentDic);
  }
}