import { ComponentElem } from "@components/interfaces";
import PopupBase from "./PopupBase";
import Popups from "./Popups";
import { gsap } from "gsap";

// const TEXT_LINE_HEIGHT_PC = 26,
// TEXT_LINE_HEIGHT_MOBILE = 32;

const TEXT_THEHOLD = 38;

type MyOptions =
  {
    complexTitle?: string,
    title?: string,
    text?: string,
    mode?: string,
    cancelLabel?: string | false,
    confirmLabel?: string,
    autoClose?: boolean,
    closeByCover?: boolean,
    closeByX?: boolean,
    resolveAtStart?: boolean,
    theme?: "default" | "eula" | "title-with-line"
  }

let $pending = $(`<div id="pending"><div class="icon"></div></div>`),
  _isPending = false;


export default class EZAlert extends PopupBase
{
  static instance: EZAlert;

  autoClose_cover: boolean = false;

  static pending(text: string | boolean = "請稍候", cb?: Function)
  {
    if (text === false) {
      if (!_isPending) return;
      _isPending = false;
      gsap.killTweensOf($pending);
      gsap.to($pending, {
        duration: .05, opacity: 0, onComplete: () =>
        {
          $pending.detach();
          if (cb) cb();
        }
      });
    }
    else {
      if (_isPending) return;
      _isPending = true;
      $pending.find(".text").text(text);
      $(`body`).append($pending);
      gsap.killTweensOf($pending);
      gsap.set($pending, { opacity: 0 });
      gsap.to($pending, {
        duration: .2, opacity: 1, onComplete: () =>
        {
          if (cb) cb();
        }
      });

    }
  }

  /**
   * 
   * @param title 標題
   * @param text 內文
   * @param autoClose 是否自動執行關閉(當關閉後需要呼叫另一個 popup 時, 設定為 false 避免背景黑底閃爍)
   * @param resolveAtStart 決定 promise 在關閉前還是關閉後 resolve
   * @param closePending 自動關閉 pending 狀態
   * @returns 
   */
  static alert(title?: string, text?: string, autoClose: boolean = true, resolveAtStart: boolean = true, closePending: boolean = true)
  {
    if (closePending) this.pending(false);
    return this.instance.alert(title, text, undefined, autoClose, resolveAtStart);
  }

  static alertJSON(title: string, jsonObj: any)
  {

    let text = JSON.stringify(jsonObj, undefined, 2);
    text = `<pre>${text}</pre>`;

    return EZAlert.alert(title, text);
  }

  constructor(elem: ComponentElem)
  {
    super(elem);
    EZAlert.instance = this;

    this.$root.find(".btn-close").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      
      Popups.close();
    });

    window.EZ.alert = (title: string, text: string) =>
    {
      return EZAlert.alert(title, text);
    };

    window.EZ.alertJSON = (title: string, obj: any) =>
    {
      return EZAlert.alertJSON(title, obj);
    };
  }

  show(options: MyOptions)
  {
    let complexTitle = options.complexTitle,
      title = options.title,
      text = options.text;

    if (!title && !text) throw new Error(`title 和 text 不能皆為空值`);

    options.theme = options.theme || "default";
    options.closeByCover = options.closeByCover === true? true: false;

    this.autoClose_cover = options.closeByCover;

    this.$root.find(".btn-close").css("display", options.closeByX === true? "block": "none");
    

    let detailMode = "all";
    if (!title) detailMode = "text-only";
    if (!text) detailMode = "title-only";
    if (complexTitle) detailMode = "complex";

    this.$root.attr("detail-mode", detailMode);
    this.$root.attr("mode", options.mode);


    this.$root.attr("theme", options.theme);

    this.$root.find(".btn-cancel").text(options.cancelLabel).css("display", options.cancelLabel === false? "none": "");
    this.$root.find(".btn-confirm").text(options.confirmLabel);

    let noButtons = (options.mode === "alert" && options.cancelLabel === false);
    this.$root.find(".buttons").css("display", noButtons? "none": "");

    let $text = this.$root.find(".text"),
      $title = this.$root.find(".title"),
      $complexTitle = this.$root.find(".complex-title");

    $title.text(title ? title : "");
    $text[0].innerHTML = (text ? text : "");

    $complexTitle.text(complexTitle? complexTitle: "");

    let thehold = detailMode === "complex"? 38: TEXT_THEHOLD;

    if (text && options.theme === "default") {
      this.$root.css("visibility", "hidden");
      $("#popups").append(this.$root);      
      
      let isSingleLine = $text.height() <= thehold;
      this.$root.css("visibility", "").detach();

      $text.css("text-align", isSingleLine ? "center" : "");
    }
    else {
      $text.css("text-align", "");
    }

    Popups.to("ez-alert");

    return new Promise((resolve) =>
    {
      this.$root.find(".btn-cancel").off().on("click", (event: Event) =>
      {
        event.preventDefault();

        if (options.autoClose) {
          if (options.resolveAtStart) {
            Popups.close();
            resolve(false);
          }
          else {
            Popups.close().then(()=>
            {
              resolve(false);
            });
          }
        }
        else {
          resolve(false);
        }
      });

      this.$root.find(".btn-confirm").off().on("click", (event: Event) =>
      {
        event.preventDefault();

        if (options.autoClose) {
          if (options.resolveAtStart) {
            Popups.close();
            resolve(true);
          }
          else {
            Popups.close().then(()=>
            {
              resolve(true);
            });
          }
        }
        else {
          resolve(true);
        }
      });

    });
  }

  confirm(title?: string, text?: string, cancelLabel: string | false = "取消", confirmLabel: string = "確認", autoClose: boolean = true, resolveAtStart: boolean = true, options?: MyOptions)
  {
    options = Object.assign({}, { mode: "confirm", cancelLabel: cancelLabel, confirmLabel: confirmLabel, autoClose: autoClose, resolveAtStart: resolveAtStart, title: title, text: text }, options);
    return this.show(options);
  }

  alert(title?: string, text?: string, cancelLabel: string | false = "OK", autoClose: boolean = true, resolveAtStart: boolean = true, options?: MyOptions)
  {
    options = Object.assign({}, { mode: "alert", cancelLabel: cancelLabel, autoClose: autoClose, resolveAtStart: resolveAtStart, title: title, text: text }, options);
    return this.show(options);
  }

  complexConfirm(complexTitle: string, title?: string, text?: string, cancelLabel: string = "取消", confirmLabel: string = "確認", options?: MyOptions)
  {
    let outputOptions: MyOptions = {
      mode: "confirm",
      title: title,
      text: text,
      complexTitle: complexTitle,
      cancelLabel: cancelLabel,
      confirmLabel: confirmLabel,
      autoClose: true,
      resolveAtStart: true
    };

    outputOptions = Object.assign({}, outputOptions, options);

    return this.show(outputOptions);
  }
}