// import error_schema from "@schema/error.json";
// import response_message_schema from "@schema/response-message.json";
// import phone_schema from "@schema/phone.json";
// import country_schema from "@schema/country.json";
// import address_schema from "@schema/address.json";
// import gender_schema from "@schema/gender.json";
// import paging_info_schema from "@schema/paging-info.json";
// import next_url_schema from "@schema/next_url.json";
// import coupon_schema from "@schema/coupon.json";
// import basic_info from "@schema/basic-info.json";
// import passenger_info from "@schema/passenger-info.json";
// import platform_schema from "@schema/platform.json";
// import photo_schema from "@schema/photo.json";

import Ajv, { JSONSchemaType } from "ajv";
import addFormats from "ajv-formats";


let _ajv: Ajv;

let AjvProxy =
{
  init()
  {
    _ajv = new Ajv({ coerceTypes: "array" });
    // _ajv.addSchema(error_schema);

    addFormats(_ajv, ["email"]);
    addFormats(_ajv, ["date"]);

    // AjvProxy.addSchema(error_schema);
    // AjvProxy.addSchema(response_message_schema);
    // AjvProxy.addSchema(phone_schema);
    // AjvProxy.addSchema(country_schema);
    // AjvProxy.addSchema(address_schema);
    // AjvProxy.addSchema(gender_schema);
    // AjvProxy.addSchema(next_url_schema);
    // AjvProxy.addSchema(paging_info_schema);
    // AjvProxy.addSchema(coupon_schema);
    // AjvProxy.addSchema(basic_info);
    // AjvProxy.addSchema(passenger_info);
    // AjvProxy.addSchema(platform_schema);
    // AjvProxy.addSchema(photo_schema);

    // SchemaTest.test();
  },

  addSchema(schema: any, name?: string)
  {
    if (!name) name = schema["$id"];



    if (!_ajv.getSchema(name)) {
      _ajv.addSchema(schema, name);
      // console.log("add " + name);
      // console.log(schema);
    }
  },

  validate<DataType>(schemaJson: any, jsonData: any, coerceTypes: any = "array"): { data: DataType, error?: any }
  {
    _ajv.opts.coerceTypes = coerceTypes;    

    try {

      let schemaId: string = schemaJson["$id"];

      AjvProxy.addSchema(schemaJson, schemaId);
      let validate = _ajv.getSchema(schemaId);

      // console.log(validate.schema);
      // console.log("validae " + schemaId);

      if (validate(jsonData)) {
        return { data: jsonData as DataType };
      }
      else {
        console.error(`JSON Schema [${schemaId}] 驗證失敗:\n=> ` + validate.errors[0].message, { jsonData: jsonData, errors: validate.errors });
        console.dir(validate.errors[0]);

        return { error: { jsonData: jsonData, errors: validate.errors, message: `JSON Schema [${schemaId}] 驗證失敗` }, data: jsonData as DataType };
      }
    }
    catch (e) {
      console.error(e);
      return { error: e, data: null };
    }
  }
}

export default AjvProxy;