import { Common } from "@components/common/CommonTypes";
import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Popups from "./Popups";

export default class PopupBase extends ComponentBase
{
  popupId: string = "";

  isOnStage: boolean = false;

  autoClose_popup: boolean = false;
  autoClose_menu: boolean = true;
  autoClose_cover: boolean = true;

  coverColor: string = `rgba(6, 7, 7, 0.5)`;

  popupAlign: Common.Align = 5;

  $root: JQuery;

  constructor(elem: ComponentElem)
  {
    super(elem);
    this.$root.detach();
  }

  toMe()
  {
    window._ez_popup_to_(this.popupId);
  }

  setMinWidth?: {(width: number): void};
  clearDropdownSetting?: {(): void};
}