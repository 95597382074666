import { Emitter } from "strict-event-emitter";

export class Viewport<IndexEnum>
{
  /** 代表目前畫面寬度對應到哪個 ranges 設定(例如 index=0 表示目前畫面寬度介於 0~{ranges[0]} 之間的值) */
  index: number = -1;
  width: number = 0;
  height: number = 0;
  changed: boolean = false;
  isMajorChange: boolean = false;
  ranges: number[];
  // scrollbarWidth: number = 0;

  containerWidth: number = 0;
  containerHeight: number = 0;

  boundingRect: DOMRect;

  intervalId: number = -1;

  emitter: Emitter<{updated: [viewport: Viewport<{}>], majorChanged: [viewport: Viewport<{}>]}>;

  constructor(ranges?: number[])
  { 
    this.ranges = ranges === undefined ? [640, 1200, 2000] : ranges;
    this.emitter = new Emitter();
  }

  update()
  {
    let oldIndex = this.index;

    this.width = window.innerWidth;
    this.height = window.innerHeight;

    for (var i = 0; i < this.ranges.length; i++) {
      let viewportWidth = this.ranges[i];
      if (this.width <= viewportWidth) {
        break;
      }
    }

    this.index = i;
    this.changed = (oldIndex !== this.index);
    this.isMajorChange = (this.changed && (oldIndex === 0 || this.index === 0)) || oldIndex === -1;

    // if(this.index === 0)
    // {
    //   this.scrollbarWidth = 0;
    // }
    // else
    // {
    //   this.scrollbarWidth = window.innerWidth - document.body.clientWidth;
    // }

    this.boundingRect = new DOMRect(0, 0, this.width, this.height);

    this.emitter.emit("updated", this);
    if(this.isMajorChange) this.emitter.emit("majorChanged", this);
  }

  test<TTT extends keyof IndexEnum>(v: TTT){
    console.log(v);
  }

  getScrollbarWidth()
  {
    return this.index === 0? 0: window.innerWidth - document.body.clientWidth;
  }

  setRegularUpdate(booleanOrDuration: Boolean | Number = 1, onSizeChanged?: Function)
  {
    let self = this;

    if (booleanOrDuration === false) {
      window.clearInterval(this.intervalId);
    }
    else {
      if (booleanOrDuration === true) booleanOrDuration = 1;

      let duration: number = booleanOrDuration === true ? 1 : booleanOrDuration as number;

      if (self.needUpdate()) onSizeChanged.call(null);

      this.intervalId = window.setInterval(() =>
      {
        if (self.needUpdate()) onSizeChanged.call(null);

      }, duration * 1000);
    }
  }

  rsetZoomLevel(width?: number): void
  {
    if (width === undefined) width = this.ranges[0];

    let clientWidth = screen.width,
      mobileWidth = width,
      scale = clientWidth / mobileWidth;

    $('meta[name=viewport]').remove();
    $('head').append('<meta name="viewport" content="width=device-width, maximum-scale=1.0, user-scalable=0">');

    $('meta[name=viewport]').remove();
    $('head').append('<meta name="viewport" content="width=device-width, initial-scale=' + scale + '">');
  }

  needUpdate(): boolean
  {
    return (this.width !== window.innerWidth || this.height !== window.innerHeight);
  }

  toString(): string
  {
    return "w: " + this.width + ", height: " + this.height;
  }
}