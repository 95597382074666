import { ComponentElem } from "./interfaces";
import {gsap} from "gsap";

export default class ComponentBase
{
  static $sample: JQuery;

  static relyOthers: boolean = false;

  static createElem()
  {
    return this.$sample.clone()[0] as ComponentElem;
  }

  $root: JQuery;

  constructor(elem: ComponentElem)
  {
    this.$root = $(elem);
    elem._component = this;
  }

  appendTo($target: JQuery){
    $target.append(this.$root);
    gsap.from(this.$root, {duration:.2, opacity: 0});
  }

  seekComponentByName(componentName: string): any
  {
    return this.seekComponent(`[component="${componentName}"]`);
  }

  seekComponentById(id: string): any
  {
    return this.seekComponent(`#${id}`);
  }

  seekComponentByField(field: string): any
  {
    return this.seekComponent(`[data-field="${field}"]`);
  }

  seekComponentByClass(className: string): any
  {
    return this.seekComponent(`.${className}`);
  }

  seekComponent(query: string): any
  {
    let result = (<ComponentElem>this.$root.find(query)[0])
    
    if(!result)
    {
      console.error(`can't fint element with query: ${query}`);
      return null;
    }
    else if(!result._component)
    {
      console.error(`element with query: ${query} don't have component`);
      return null;
    }

    return result._component;
  }
}