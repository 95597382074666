/** Utility
 *
 * 2019/9/3 v0.0.17
 * 新增 Utility.realStringLength 方法
 * 新增 Utility.getMobileOperatingSystem 方法
 * 新增 PatternSamples["invoice"] 發票號碼正規式
 *
 * 2019/12/12 v0.0.18
 * 新增 Utility.openWindowInCenter 方法
 * 新增 Utility.isWebview 方法, 判別是否是 webview 瀏覽器 (line / facebook app)
 *
 * 2020/07/05
 * 新增 detectIE 方法, 判別IE版本
 * 
 * 2020/12/21
 * 語法整理
 * 新增 isMobile 方法, 判別是否為 mobile 平台
 * 抽離 PatternSamples 合集到另一個檔案
 * analysisUrl 方法改名為 isUrl
 * 
 * 2021/03/25
 * 改寫成 typescript, 移至 sframe namespace
 * 
 * 2021/11/02
 * 新增 copyTextToClipboard 方法, 複製文字到剪貼簿
 * 
 * 
 * 2023/4/5
 * 新增 adjustUrlParams 方法, 調整 url query
 * 
 * **/

var _mobileSystem: string;

let self;

let Utility = self =
{
  detectIE: function (): boolean | number
  {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  },

  /**
   * 檢查字串是否為網址
   * @param url 網址
   * @returns
   */
  isUrl: function (url: string): boolean
  {
    var pattern = /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
    return Boolean(url.match(pattern));
  },

  /**
   * 取得當前當前網址路徑含檔名.副檔名
   * @returns 
   */
  getPathWithFilename: function (): string
  {
    return window.location.href.replace(window.location.search, '').replace(window.location.hash, '').replace("?", '');
  },

  /**
   * 取得網址路徑(不包含檔名)
   * @param url 網址
   * @returns 
   */
  getPath: function (url?: string): string
  {
    if (!url) url = window.location.href;

    var string = url.indexOf('?') == -1 ? url : url.substr(0, url.indexOf('?'));

    if (string.indexOf('#') !== -1) {
      string = string.substr(0, string.indexOf('#'));
    }

    var array = string.split("/");
    var lastPart = array[array.length - 1];
    if (lastPart.indexOf(".") !== -1) array.pop();

    string = array.join("/");


    while (string.length && string.charAt(string.length - 1) == "/") {
      string = string.slice(0, string.length - 1);
    }

    string += "/";

    return string;
  },

  /**
   * 取得網址通訊協定(https, https ...)
   * @param url 網址
   * @returns 
   */
  getProtocol: function (url: string): string
  {
    if (!url) url = window.location.href;
    return url.split("/")[0];
  },

  /**
   * 網址參數 (此方法將快取一開始的網址, 如欲取得目前的網址參數, 請改用 getCurrentUrlParams)
   */
  urlParams: (function ()
  {
    return getUrlParams();

  }()) as { [key: string]: string },

  getCurrentUrlParams()
  {
    return getUrlParams();
  },

  replaceUrlParams(newQuery?: string, keepState: boolean = true)
  {
    let currentUrl = window.location.href.split(`?`)[0];
    // console.log(currentUrl);

    var newUrl = newQuery ? currentUrl + "?" + newQuery : currentUrl;
    keepState? window.history.replaceState({}, document.title, newUrl): window.history.pushState({}, document.title, newUrl);
  },

  adjustUrlParams(removingList?: string[], addingDic?: {[key:string]:string})
  {
    if (!window.history) return;

    let params = Utility.getCurrentUrlParams(),
      string = "",
      key,
      k,
      i = 0;

    for (key in params) {
      let flag = false;

      if (removingList) {
        for (k = 0; k < removingList.length; k++) {
          let removingKey: string = removingList[k];
          if (key === removingKey) flag = true;
        }
      }


      if (addingDic && addingDic[key]) flag = true;

      if (flag) continue;

      if (i === 0) {
        string += ("?" + key + "=" + params[key]);
      }
      else {
        string += ("&" + key + "=" + params[key]);
      }

      i++;
    }


    if (addingDic) {
      for (key in addingDic) {
        if (string === "") {
          string += ("?" + key + "=" + addingDic[key]);
        }
        else {
          string += ("&" + key + "=" + addingDic[key]);
        }
      }
    }

    var newUrl = Utility.getPathWithFilename() + string;
    window.history.replaceState({}, document.title, newUrl);

    return newUrl;
  },

  /**
   * @returns boolean, 當前網址是否為本機網址
   */
  isLocalhost: (): boolean =>
  {
    var address = window.location.toString().split("/")[2];
    return (address == "localhost" || address.split(".")[0] == "192");
  },

  /**
   * 
   * @returns boolean, 裝置是否為 ios
   */
  isiOS: function (): boolean
  {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  },

  /**
   * 把字串中的全形字當兩個字元長度計算, 回應字串總長度
   * @param string
   * @returns 
   */
  getRealStringLength: function (string: string): number
  {
    return string.replace(/[^\x00-\xff]/g, "**").length;
  },

  /**
   * 平台判定 (此語法版本可能過舊)
   * @returns 
   */
  getMobileOperatingSystem: function ()
  {
    if (_mobileSystem) return _mobileSystem;

    var userAgent = navigator.userAgent || navigator.vendor;

    _mobileSystem = "unknown"

    if (/windows phone/i.test(userAgent)) {
      _mobileSystem = "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      _mobileSystem = "Android";
    }

    //@ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {

      _mobileSystem = "iOS";
    }

    return _mobileSystem;
  },

  /**
   * @returns boolean, 裝置是否為 mobile
   */
  isMobile: function (): boolean
  {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  },

  /**
   * @returns boolean, 裝置是否為 app 內建瀏覽器(webview)?
   */
  isWebview: function (): boolean
  {
    var u = navigator.userAgent,
      ua = navigator.userAgent.toLowerCase(),
      weixinMatch = ua.match(/MicroMessenger/i),
      isLineApp = u.indexOf("Line") > -1, // Line 內建瀏覽器
      isFbApp = u.indexOf("FBAV") > -1, // FB App 內建瀏覽器
      isWeixinApp = weixinMatch !== null // 微信內建瀏覽器

    return !!(isLineApp || isFbApp || isWeixinApp);
  },


  /**
   * 在螢幕中央打開新視窗
   * @param url
   * @param title
   * @param w 視窗寬度
   * @param h 視窗高度
   */
  openWindowInCenter: function (url: string, title: string, w: number, h: number)
  {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var systemZoom = width / window.screen.availWidth;
    var left = (width - w) / 2 / systemZoom + dualScreenLeft;
    var top = (height - h) / 2 / systemZoom + dualScreenTop;
    //var string = ', width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left;
    var string = ', width=' + w + ', height=' + h + ', top=' + top + ', left=' + left;

    //var newWindow = window.open(url, title, 'scrollbars=yes' + string);
    var newWindow = window.open(url, title, "toolbar=yes,location=yes,directories=no,status=no,menubar=yes,scrollbars=yes,resizable=yes, copyhistory=yes" + string);

    // Puts focus on the newWindow    
    ///@ts-ignore
    if (window.focus) newWindow.focus();
  },

  copyTextToClipboard(text: string, cb?: (success: boolean) => void)
  {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, cb);
      return;
    }
    navigator.clipboard.writeText(text).then(function ()
    {
      // console.log('Async: Copying to clipboard was successful!');
      if (cb) cb.call(null, true);
    }, function (err)
    {
      // console.error('Async: Could not copy text: ', err);
      if (cb) cb.call(null, false);
    });
  }
};

export default Utility;



function fallbackCopyTextToClipboard(text: string, cb?: (success: boolean) => void)
{
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "-600px";
  textArea.style.left = "-600px";
  // textArea.style.display = "none";
  textArea.style.position = "fixed";


  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    //   var msg = successful ? 'successful' : 'unsuccessful';
    //   console.log('Fallback: Copying text command was ' + msg);
    if (cb) cb.call(null, successful);

  } catch (err) {
    if (cb) cb.call(null, false);
  }

  document.body.removeChild(textArea);
}


function getUrlParams()
{
  var url = window.location.href;
  
  var paramString = url.split("?")[1];
  if (!paramString) { return {}; }
  paramString = paramString.split("#")[0];
  var urlParams: { [key: string]: string } = {};
  var array = paramString.split("&");

  for (var i = 0; i < array.length; i++) {
    var array2 = array[i].split("=");
    urlParams[array2[0]] = decodeURIComponent(array2[1]);
  }

  return urlParams;
}