import PageRoot from "@/src/scripts/components/common/PageRoot";
import Components from "@components/Compoents";
import Popups from "@components/popups/Popups";
import ApiProxy from "@ts/ApiProxy";
import GS from "@ts/GlobalSetting";
import { ScrollListener } from "@ts/sframe/ScrollListener";
import Utility from "@ts/sframe/Utility";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import Tools from "@ts/Tools";

import { gsap } from "gsap";
import { ScrollToPlugin, ScrollTrigger, MotionPathPlugin } from "gsap/all";
import AjvProxy from "./ts/AjvProxy";



gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin);



const self: any =
{
  isLayoutOn: false,

  init: ()=>
  {
    // self.build();

    GS.init();    
    let prefix = GS.setting.testEnv? "": "./";
    $(`#shared-popups`).load(prefix + "shared-popups.html", (responseTxt, statusTxt, xhr)=>
    {
      self.build(); 
    });
  },

  build: function ()
  { 
    AjvProxy.init();

    ApiProxy.setApiPath(GS.setting.apiPath);
    ApiProxy.setApiExtension(GS.setting.apiExt);
    if(GS.setting.testEnv)
    {
      ApiProxy.setSimplateDelay(500);
    }

    window.EZ = {};

    // ///@ts-ignore
    // if (window._API_DATA_) {
    //   ///@ts-ignore
    //   ApiProxy.applyTestData(window._API_DATA_);
    // }

    Tools.init();
    Tools.completeLinks($('body'));

    if (Utility.urlParams.layout) {
      $("body").toggleClass("layout-mode", true);
      if (Utility.urlParams.popup) {
        $("body").toggleClass("popup-layout-mode", true);
      }
      self.isLayoutOn = true;
    }

    window.addEventListener("resize", () =>
    {
      ViewportRoot.update();
    });

    if(GS.setting.testEnv && Utility.urlParams["is-logout"] === "1")
    {
      ApiProxy.getCommonPageData().login_state.is_login = false;
    }

    if(self.preInitPage) self.preInitPage();    

    Components.init();

    PageRoot.init(true);
    Popups.init();

    // $("body").on("contextmenu", "img", function(e) {
    //   console.log("cc");
      
    //   return false;
    // });

    document.addEventListener('contextmenu', event => event.preventDefault());

    if (self.initPage) self.initPage();

    ScrollListener.active();
    ViewportRoot.emitter.addListener("updated", ()=>
    {
      ScrollListener.update();
      
      if (self.isLayoutOn && ViewportRoot.changed) {
        var ext = ".jpg";
        $("#layout").html("<img src='./assets/images/layout/" + Utility.urlParams.layout + ext + "'>");
      }
    });

    ViewportRoot.update();

    $('[lazy-loading="pending"]').attr("lazy-loading", "done");

    let $loading = $("#main-loading");
    gsap.to($loading, {duration: .3, opacity: 0, ease: "power2.in", onComplete: ()=>
    {
      $loading.detach();
    }})

  },
  preInitPage: null,
  initPage: null
};

window.addEventListener('load', () =>
{
  self.init();
});




export default self;

console.log("<entry.main.ts>");
