/*****
 * 未處理:
 *  scrolllock true 時, 螢幕寬度再度改變的處置
 */

import GS from "@ts/GlobalSetting";
import ViewportRoot from "@ts/sframe/ViewportRoot";
import Nav from "./Nav";

let $root: JQuery,
  $scrollWrapper: JQuery,
  $pageContent: JQuery,
  $sectionWrapper: JQuery,
  $footer: JQuery;

let _isScrollLocking: boolean = false;

let _oldScrollTop: number,
  _oldScrollLeft: number;

let _scaleInMiddleMode = false,
  _contentScaleRate = 1;

class PageRoot
{
  static init(scaleInMiddleMode: boolean = false)
  {
    _scaleInMiddleMode = scaleInMiddleMode;

    $root = $("#page-root")
    $scrollWrapper = $root.find(".scroll-wrapper")
    $pageContent = $root.find("#page-content");
    $sectionWrapper = $root.find("#section-wrapper");

    // $footer = $("footer");

    // ViewportRoot.emitter.on("majorChanged", ()=>
    // {
    //   let minHeight = ViewportRoot.height - $footer.height() - GS.getNavHeight(); 
    //   $sectionWrapper.css("min-height", minHeight > 0? minHeight: "");
    // });

    Nav.init();

    if (_scaleInMiddleMode) {
      ViewportRoot.emitter.on("updated", () =>
      {
        _contentScaleRate = updateHeight();
        Nav.resize(_contentScaleRate);
        // console.log(`content scale rate: ${_contentScaleRate}`);
      });
    }
  }

  static isScrollLocking()
  {
    return _isScrollLocking;
  }

  static toggleScrollLock(lockOn: boolean)
  {
    if (lockOn === _isScrollLocking) return;
    _isScrollLocking = lockOn;

    if (_isScrollLocking) {
      _oldScrollTop = window.scrollY;
      _oldScrollLeft = window.scrollX;
      

      $root.attr("scoll-locking", "true")
      $scrollWrapper.css("left", -_oldScrollLeft)
      $scrollWrapper.css("top", -_oldScrollTop)
    }
    else {
      $root.attr("scoll-locking", "false")

      window.scrollTo(_oldScrollLeft, _oldScrollTop)
      $scrollWrapper.css("left", "")
      $scrollWrapper.css("top", "")
    }
  }

}

function updateHeight()
{
  let vp = ViewportRoot;

  let scale = 1;

  if (vp.index === 1) {
    let minWidth = vp.ranges[1];

    scale = vp.width / minWidth;

    let rawHeight = $pageContent.height(),
      newHeight = rawHeight * scale;

    $pageContent.css({
      "transform": "scale(" + scale + ")"
    });

    $root.css({
      "height": newHeight,
      "width": "",
      "min-width": "auto"
    });
  }
  else {
    $pageContent.css({
      "transform": ""
    });

    $root.css({
      "height": "",
      "width": "",
      "min-width": ""
    });
  }

  return scale;
}

export default PageRoot;